<template>
  <div>
    <div class="columns is-centered">
      <div class="column is-full">
        <div v-if="isLoading">
          <Loading />
        </div>
        <div :class="stubbed ? 'box block' : ''" v-else>
          <h1 class="title">TESTS</h1>
          <b-field>
            <b-switch
                :value="sort === 'updatedAt'"
                @input="changeSortBy"
                passive-type="is-dark"
                type="is-warning"
              >
                {{ sort === 'updatedAt' ? "UPDATED" : "TEST DATE" }}
              </b-switch>
          </b-field>
          <h3 class="subtitle">Page: {{ page }}</h3>
          <p class="panel-tabs">
            <a :class="isAllActive" @click="getAll">All</a>
            <a :class="isSelfActive" @click="getSelf">Self</a>
            <a
              v-bind:class="isStatusActive('Open') ? 'is-active' : ''"
              @click="() => getTestByStatus('Open')"
              >Open</a
            >
            <a
              v-bind:class="isStatusActive('To Be Booked') ? 'is-active' : ''"
              @click="() => getTestByStatus('To Be Booked')"
              >
                To Be Booked
            </a>
            <a
              v-bind:class="isStatusActive('Booked') ? 'is-active' : ''"
              @click="() => getTestByStatus('Booked')"
              >
                Booked
            </a>
            <a
              v-bind:class="isStatusActive('Dispute') ? 'is-active' : ''"
              @click="() => getTestByStatus('Dispute')"
              >
                Dispute
            </a>
            <a
              v-bind:class="isStatusActive('Rebooked') ? 'is-active' : ''"
              @click="() => getTestByStatus('Rebooked')"
              >
                Rebooked
            </a>
            <a
              v-bind:class="isStatusActive('Duplicate') ? 'is-active' : ''"
              @click="() => getTestByStatus('Duplicate')"
              >
                Duplicate
            </a>
            <a
              v-bind:class="isStatusActive('Cancelled') ? 'is-active' : ''"
              @click="() => getTestByStatus('Cancelled')"
              >
                Cancelled
            </a>
          </p>
          <table v-if="!isLoading" class="table is-fullwidth is-hoverable">
            <thead>
              <tr>
                <th>Created</th>
                <th>Name</th>
                <th>Email</th>
                <th>Type</th>
                <th>Additional Support</th>
                <th>Test Time</th>
                <th>Test Date</th>
                <th v-if="agent.type === 'Admin'">Process by</th>
                <th v-if="!filter">Status</th>
              </tr>
            </thead>
            <tbody v-if="tests.length">
              <tr
                @click="navigateToOrder(test.orderId)"
                v-for="test in netTests"
                :key="test.id"
                :class="test.Order.Payment &&
                 test.Order.Payment.chargeId ? 'has-background-success-light' : ''"
              >
                <td>
                  {{ new Date(test.createdAt).toDateString() }}
                </td>
                <td>{{ test.Order.User.title }}
                  {{ test.Order.User.firstName }} {{ test.Order.User.lastName }}
                </td>
                <td>{{ test.Order.User.email || "No email" }}</td>
                <td>{{ test.testType }}</td>
                <td>{{ test.language }}</td>
                <td>
                    {{ test.testTime }}
                </td>
                <td>
                    {{ new Date(test.date).toDateString() }}
                </td>
                <td v-if="agent.type === 'Admin'">
                  <span v-if="test.Order.backendAgentId">
                    <b-tag type="is-light is-primary">
                      {{ getAgentById(test.Order.backendAgentId)}}
                    </b-tag>
                  </span>
                  <span v-else>
                    <b-tag type="is-light is-primary">
                      N/A
                   </b-tag>
                  </span>
                </td>
                <td v-if="!filter">
                  {{ test.status }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="!stubbed" class="is-flex-direction-row">
      <b-button
        class="is-justify-content-flex-start"
        @click="decrementPage"
        :disabled="page === 1"
        >Prev</b-button
      >
      <b-button
        class="is-justify-content-flex-start"
        @click="
          () => {
            updateTestsViewPage(1);
            queryTests();
          }
        "
        >1</b-button
      >
      <b-button
        class="is-justify-content-flex-start"
        @click="
          () => {
            updateTestsViewPage(5);
            queryTests();
          }
        "
        >5</b-button
      >
      <b-button
        class="is-justify-content-flex-start"
        @click="
          () => {
            updateTestsViewPage(10);
            queryTests();
          }
        "
        >10</b-button
      >
      <b-button
        class="is-justify-content-flex-start"
        @click="
          () => {
            updateTestsViewPage(20);
            queryTests();
          }
        "
        >20</b-button
      >
      <b-button class="is-justify-content-flex-end" @click="incrementPage"
        >Next</b-button
      >
    </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Loading from '@/components/Loading.vue';

import { navigateToOrder, getAgentById } from '@/common';

export default {
  name: 'TestList',
  data: () => ({
    isLoading: true,
  }),
  props: {
    stubbed: Boolean,
  },
  async mounted() {
    try {
      await this.queryTests();
    } catch (error) {
      console.error(error);
      this.$router.push({ name: 'Login' });
    } finally {
      this.isLoading = false;
    }
    return null;
  },
  components: {
    Loading,
  },
  methods: {
    ...mapActions([
      'queryTests',
      'updateTestsViewPage',
      'setTestsViewFilter',
      'setTestsViewSort',
      'setTestsViewFilterStatus',
    ]),
    navigateToOrder,
    getAgentById,
    isStatusActive(status) {
      return this.filter === 'status' && this.status === status;
    },
    async changeSortBy(value) {
      this.isLoading = true;
      if (value) {
        this.setTestsViewSort('updatedAt');
      } else {
        this.setTestsViewSort('date');
      }
      try {
        await this.queryTests();
      } catch (error) {
        console.error(error);
        this.$router.push({ name: 'Home' });
      } finally {
        this.isLoading = false;
      }
    },
    async decrementPage() {
      this.updateTestsViewPage('DECREMENT');
      this.isLoading = true;
      try {
        await this.queryTests();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async incrementPage() {
      this.updateTestsViewPage('INCREMENT');
      this.isLoading = true;
      try {
        await this.queryTests();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async getAll() {
      this.setTestsViewFilter(null);
      this.setTestsViewFilterStatus(null);

      this.isLoading = true;
      try {
        await this.queryTests();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async getSelf() {
      this.setTestsViewFilter('self');
      this.setTestsViewFilterStatus(null);

      this.isLoading = true;
      try {
        await this.queryTests();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async getTestByStatus(status) {
      this.setTestsViewFilter('status');
      this.setTestsViewFilterStatus(status);

      this.isLoading = true;
      try {
        await this.queryTests();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    ...mapState({
      tests: ({ testsView: { tests } }) => tests,
      page: ({ testsView: { page } }) => page,
      sort: ({ testsView: { sort } }) => sort,
      status: ({ testsView: { status } }) => status,
      filter: ({ testsView: { filter } }) => filter,
      agent: ({ agentData: { agent } }) => agent,
      allAgents: ({ agentData: { allAgents } }) => allAgents,
    }),
    netTests() {
      if (this.stubbed) {
        return this.tests && this.tests.slice(0, 6);
      }
      return this.tests;
    },
    isAllActive() {
      return this.filter !== 'self' && this.filter !== 'status'
        ? 'is-active'
        : '';
    },
    isSelfActive() {
      return this.filter === 'self' ? 'is-active' : '';
    },
  },
};
</script>
<style scoped>
table {
  cursor: pointer;
}
</style>
